import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Main/index.tsx";
import { Grid, Button, Box } from '@material-ui/core';
import { Provider, YellowText, PaddedBox } from '@/components/Developers/Provider';
import { HighlightCardWithIcon } from '@/components/HighlightCard';
import DemoForm from '@/components/DemoForm';
import APIAccess from '@/assets/developers/api-access.webp';
import { BlueText } from '@/components/Solutions/Provider';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Provider mdxType="Provider">
      <Box textAlign="center" padding={7} mdxType="Box">
        <h4 {...{
          "id": "our-apis-are-simple-and-easy-to-understand-allowing-developers-to-spend-more-time-building-novel-solutions",
          "style": {
            "position": "relative"
          }
        }}>{`Our APIs are simple and easy to understand, allowing developers to spend more time building novel solutions.`}<a parentName="h4" {...{
            "href": "#our-apis-are-simple-and-easy-to-understand-allowing-developers-to-spend-more-time-building-novel-solutions",
            "aria-label": "our apis are simple and easy to understand allowing developers to spend more time building novel solutions permalink",
            "className": "anchor after"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a></h4>
      </Box>
      <PaddedBox padding={4} mdxType="PaddedBox">
  
        <HighlightCardWithIcon icon={APIAccess} mdxType="HighlightCardWithIcon">
          <Box mdxType="Box">
  <BlueText variant="h5" mdxType="BlueText">
    Leverage our iFrame and Dashboard for two-line integrations.
  </BlueText>
          </Box>
          <p>{`Using our iFrame and Dashboard, developers can achieve two-line integration and be up and running in minutes. We also provide
options for a deep API integration allowing more control over the customer's journey and overall experience.`}</p>
          <p>{`Using the API you can drive a FrontEnd from your own BackEnd service which communicates with TaleFin via the API & Webhook events,
for a more indepth and controlled workflow.`}</p>
          <p>{`The iFrame is heavily customisable and the integration of choice for most use cases. We've engineered a reliable, enjoyable user
experience for the customer no matter the platform, browser, or behaviour of the client side device.`}</p>
        </HighlightCardWithIcon>
      </PaddedBox>
    </Provider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      